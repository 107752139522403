
import OrganizationDetail from '@/components/organizations/OrganizationDetail.vue';
import Vue from 'vue';

export default Vue.extend({
  props: ['organizationId'],
  components: { OrganizationDetail },
  metaInfo: {
    title: 'Organiztion - '
  },
  name: 'Organiztion'
});
